/* App.css or Background.css */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  /* overflow: hidden; Prevents scrolling */

  color: #00008b;
  font-family: "Staatliches", sans-serif;
  font-size: 18px;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

h1 {
  text-align: center;
  font-size: 2rem;
}
