#bg-wrap {
    position: fixed; /* Ensures it stays fixed on the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Make sure the background is behind all other content */
    overflow: hidden;
}
  
#bg-wrap svg {
    display: block;
    width: 100%;
    height: 100%;
}

.container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .box {
    width: 40vmin;
    height: 40vmin;
    border: 1px dashed rgba(255,255,255,0.4);
    position: relative;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 1px dashed rgba(255,255,255,0.4);
      transform: scale(1.42);
    }
  }
  
  .spin-container {
    width: 100%;
    height: 100%;
    animation: spin 12s ease-in-out infinite alternate;
    position: relative;
  }
  
  .shape {
    width: 100%;
    height: 100%;
    transition: border-radius 1s ease-out;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    animation: morph 8s ease-in-out infinite both alternate;
    position: absolute;
    overflow: hidden;
    z-index: 5;
  }

.highlight-bg {
    background-color: #ffff99;
    padding: 0 5px; 
}
.highlight-underline {
    border-bottom: 3px solid #ff4500; 
}
.highlight-box {
    display: inline-block;
    padding: 5px;
    border: 2px solid #1e90ff;
    border-radius: 5px; 
}

.home-text {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
}
.home-text p {
    margin: 0;
}
.tag-line {
    margin-top: 30px;
    font-size: large;
    text-align: center;
}
.image-container {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: auto;
}

.image-hover {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-hover img {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.normal-img {
  animation: bounce 2s infinite;
  z-index: 1;
}

.hover-img {
  opacity: 0;
  z-index: 2;
}

.image-hover:hover .normal-img {
  opacity: 0;
  transform: scale(0.8);
}

.image-hover:hover .hover-img {
  opacity: 1;
  transform: scale(1.1);
}

/* Funny bouncing animation */
@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.logo {
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url('./assets/logo.png'); /* Replace with your logo URL */
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  animation: shoot 1.5s forwards;
}

/* Keyframe for shooting the logo in random directions */
@keyframes shoot {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(var(--x), var(--y)) rotate(360deg);
  }
}

.random-image {
  position: absolute;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s ease;
  animation: moveToCorner 3s forwards;
}

/* Animation to move the image to a corner */
@keyframes moveToCorner {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(var(--x), var(--y)) rotate(720deg);
  }
}

button {
  -webkit-appearance: none;
  background: -webkit-gradient(to right, #00008b 0%, #fceeb5 50%, #ee786e 100%);
  background: linear-gradient(to right, #00008b 0%, #fceeb5 50%, #ee786e 100%);
  background-size: 500%;
  border: none;
  border-radius: 5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #fff;
  cursor: pointer;
  font: 1.5em Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 5rem;
  letter-spacing: 0.05em;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 20rem;
  font-family: "Bungee", sans-serif;
}

button:hover {
  animation-name: gradient;
  -webkit-animation-name: gradient;
  animation-duration: 2s;
  -webkit-animation-duration: s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100%;
  }
}

.linked {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
}

.link-button {
  width: 5rem;
}

.text {
  text-align: center;
}